import { React, useEffect, useRef, useState } from "react";
import { Link, useLocation } from 'react-router-dom';
import './Works.css';
import $ from 'jquery';

import WorkSub from './Subpages/WorkSub.js';

function Works(props) {

  useEffect(()=>{
    $('.Works-banner-tabs-menu').find('li').removeClass('Choosed');
    if(props.PageType==="Applications"){
      $('.Works-banner-tabs-menu').find('li:eq(0)').addClass('Choosed');
    }
    else{
      $('.Works-banner-tabs-menu').find('li:eq(1)').addClass('Choosed');
    }
  },[]) 

  useEffect(()=>{
    $('.Works-banner-tabs-menu').find('li').removeClass('Choosed');
    if(props.PageType==="Applications"){
      $('.Works-banner-tabs-menu').find('li:eq(0)').addClass('Choosed');
    }
    else{
      $('.Works-banner-tabs-menu').find('li:eq(1)').addClass('Choosed');
    }
  },[props.PageType]) 
  
  return (
    <div className="Works">
      <div className="Works_PC flex flex-col">
        <div className="Works_PC-banner flex w-full con items-center">
          <div className="Works_PC-banner-left flex items-center">
            Works
          </div>
          <div className="flex w-10"></div>
          <div className="Works_PC-banner-right flex flex-col h-full justify-center">
            <div className="flex h-5"></div>
            <div className="flex">Here’s my works throughout Life</div>
          </div>
        </div>
        <div className="Works_PC-tabs flex w-full h-full justify-end flex-col">
          <ul className="Works-banner-tabs-menu flex w-full justify-end con">
            <li className="flex justify-center items-center cursor-pointer">
              <Link to="/Works/Applications" className="flex flex-1 itmes-center justify-center">Applications</Link>
            </li>
            <li className="flex justify-center items-center cursor-pointer">
              <Link to="/Works/Content" className="flex flex-1 itmes-center justify-center">Content</Link>
            </li>
          </ul>
          <div className="Works_PC-banner-tabs-pages flex flex-1 w-full h-full bg-white">  
            <WorkSub db={props.db} PageType={props.PageType}/>
          </div>
        </div>
      </div>

      <div className="Works_Tab flex flex-col">
        <div className="Works_Tab-banner flex w-full flex-col justify-center tab_con">
          <div className="Works_Tab-banner-left flex">
            Works
          </div>
          <div className="Works_Tab-banner-right flex flex-col">
            <div className="flex h-1"></div>
            <div className="flex pl-2">Here’s my works throughout Life</div>
          </div>
          <div className="flex h-8"></div>
        </div>
        <div className="Works_Tab-tabs flex w-full h-full justify-end flex-col">
          <ul className="Works-banner-tabs-menu flex justify-end">
            <li className="flex justify-center items-center">
              <Link to="/Works/Applications">Applications</Link>
            </li>
            <li className="flex justify-center items-center">
              <Link to="/Works/Content">Content</Link>
            </li>
          </ul>
          <div className="Works_Tab-banner-tabs-pages flex flex-1 w-full h-full bg-white">  
            <WorkSub db={props.db} PageType={props.PageType}/>
          </div>
        </div>
      </div>

      <div className="Works_MB flex flex-col">
        <div className="Works_MB-banner flex flex-col w-full">
          <div className="Works_MB-banner-top flex">
            Works
          </div>
          <div className="Works_MB-banner-bottom flex flex-col w-full h-full">
            <div className="flex">Here’s my works throughout Life</div>
          </div>
          <div className="flex h-10"></div>
        </div>
        <div className="Works_MB-tabs flex w-full h-full justify-end flex-col">
          <ul className="Works-banner-tabs-menu flex w-full justify-end">
            <li className="flex flex-1 justify-center items-center">
              <Link to="/Works/Applications">Applications</Link>
            </li>
            <li className="flex flex-1 justify-center items-center">
              <Link to="/Works/Content">Content</Link>
            </li>
          </ul>
          <div className="Works_MB-banner-tabs-pages flex flex-1 w-full h-full bg-white">  
            <WorkSub db={props.db} PageType={props.PageType}/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Works;
