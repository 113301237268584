import { React, useEffect, useRef, useState } from "react";
import $ from 'jquery';
import Axios from 'axios';

import './Commission.css';

function Commission(props) {

  // 입력요소들
  const LessonCost = 10000;

  const [CategorySelect, SetCategorySelect] = useState('');  // 카테고리
  const CategoryChange = (e) => { SetCategorySelect(e.target.value); };

  const [TypeSelect, SetTypeSelect] = useState('');  // 종류
  const TypeChange = (e) => { SetTypeSelect(e.target.value); };

  const [TypeEx, SetTypeEx] = useState('');  // 종류 기타
  const TypeExChange = (e) => { SetTypeEx(e.target.value); };

  const [TaskArea, SetTaskArea] = useState({    // 작업범주
    taskDesign: false,
    taskCoding: false,
  });
  const TaskAreaChange = (checkboxName) => {
    SetTaskArea((prevCheckboxes) => ({
      ...prevCheckboxes,
      [checkboxName]: !prevCheckboxes[checkboxName],
    }));
  };
  
  const [CodingSet, SetCodingSet] = useState('');  // 사용언어 및 환경
  const CodingSetChange = (e) => { SetCodingSet(e.target.value); };

  const [MainFunction, SetMainFunction] = useState({    // 주요 구현기능
    functionMember: false,
    functionCraw_Api: false,
    functionMail_Phone: false,
    functionPayment: false,
    functionEx: false,
  });
  const MainFunctionChange = (checkboxName) => {
    SetMainFunction((prevCheckboxes) => ({
      ...prevCheckboxes,
      [checkboxName]: !prevCheckboxes[checkboxName],
    }));
  };

  const [MainFunctionEx, SetMainFunctionEx] = useState('');  // 구현기능 기타
  const MainFunctionExChange = (e) => { SetMainFunctionEx(e.target.value); };

  const [ChannelNum, SetChannelNum] = useState({    // 주요 구현기능
    functionMember: 1,
    functionCraw_Api: 1,
    functionMail_Phone: 1,
    functionPayment: 1,
    functionEx: 1,
  });
  const ChannelNumChange = (checkboxName, value) => {
    SetChannelNum((prevCheckboxes) => ({
      ...prevCheckboxes,
      [checkboxName]: value,
    }));
  };

  const [Days, SetDays] = useState(1);  // 기간(일)
  const DaysChange = (e) => { SetDays(e.target.value); };
  const [Times, SetTimes] = useState(1);  // 시간
  const TimesChange = (e) => { SetTimes(e.target.value); };

  const [PageNum, SetPageNum] = useState(1);  // 페이지(쪽) 수
  const PageNumChange = (e) => { SetPageNum(e.target.value); };

  const [Calculate, SetCalculate] = useState(0);
  const [CalculateKmong, SetCalculateKmong] = useState(0);

  useEffect(()=>{
    $('.Commission .CommissionPrint').stop().slideUp(0);
    $('.Commission .CommissionSend').stop().slideUp(0);
  },[])

  useEffect(()=>{
    Reset();
    $('.Commission .CommissionSend').stop().slideUp(500);

    if(CategorySelect!==""){
      $('.Commission .CommissionPrint').stop().slideDown(500);
    }else{
      $('.Commission .CommissionPrint').stop().slideUp(0);
    }
  },[CategorySelect])

  useEffect(()=>{
    CalculateSubmit();
  },[CategorySelect, TypeSelect, TypeEx, TaskArea, CodingSet, MainFunction, MainFunctionEx, ChannelNum, Days, Times, PageNum])

  // 초기화 함수
  function Reset(){
    SetTypeSelect(''); 
    SetTypeEx('') ;
    SetTaskArea({    // 작업범주
      taskDesign: false,
      taskCoding: false,
    }); 
    SetCodingSet(''); 
    SetMainFunction({    // 주요 구현기능
      functionMember: false,
      functionCraw_Api: false,
      functionMail_Phone: false,
      functionPayment: false,
      functionEx: false,
    });
    SetMainFunctionEx(''); 
    SetChannelNum({    // 주요 구현기능 채널수
      functionMember: 1,
      functionCraw_Api: 1,
      functionMail_Phone: 1,
      functionPayment: 1,
      functionEx: 1,
    });
    SetDays(1); 
    SetTimes(1); 
    SetPageNum(1);
  }

  // 입력값들이 변동하면 작동한다.
  const CalculateSubmit = (e) => {
    let result=0;
    let unitPrice=0;
    let skills=0;
    let pageNum = PageNum;

    let basic = {
      명함 : 30000,
      카드 : 50000,
      전단지 : 80000,
      카탈로그 : 80000,
      배너 : 200000,
      간판 : 200000,
      기타_디자인 : 50000,
      프로그래밍_웹앱 : 150000,
      프로그래밍_어플 : 150000,
      프로그래밍_디버깅 : 150000,
      프로그래밍_디자인 : 50000,
      기타_프로그래밍 : 50000,
      강의 : 10000,
    };

    let skill = {
      functionMember : 100000,
      functionCraw_Api : 200000,
      functionMail_Phone : 200000,
      functionPayment : 200000,
      functionEx : 200000,
    }

    if(CategorySelect==="강의"){
      unitPrice += basic.강의 * Days * Times;
    }

    switch (TypeSelect) {
      case "명함":
        unitPrice += basic.명함;
        break;
      
      case "카드":
        unitPrice += basic.카드;
        break;

      case "전단지":
        unitPrice += basic.전단지;
        break;

      case "카탈로그":
        unitPrice += basic.카탈로그;
        break;
      
      case "배너":
        unitPrice += basic.배너;
        break;

      case "간판":
        unitPrice += basic.간판;
        break;

      case "기타_디자인":
        unitPrice += basic.기타_디자인;
        break;

      default:
        break;
    }

    if(TaskArea.taskCoding){
      switch (TypeSelect) {
        case "웹앱":
          unitPrice += basic.프로그래밍_웹앱;
          break;
        
        case "어플":
          unitPrice += basic.프로그래밍_어플;
            break;
  
        case "디버깅":
          unitPrice += basic.프로그래밍_디버깅;
          break;
  
        case "기타_프로그래밍":
          unitPrice += basic.기타_프로그래밍;
          break;
  
        default:
          break;
      }

    }

    if(TaskArea.taskDesign){
      unitPrice += basic.프로그래밍_디자인;
    }
    
    if(MainFunction.functionMember) { skills += skill.functionMember * ChannelNum.functionMember; }
    if(MainFunction.functionCraw_Api) { skills += skill.functionCraw_Api * ChannelNum.functionCraw_Api; }
    if(MainFunction.functionMail_Phone) { skills += skill.functionMail_Phone * ChannelNum.functionMail_Phone; }
    if(MainFunction.functionPayment) { skills += skill.functionPayment * ChannelNum.functionPayment; }
    if(MainFunction.functionEx) { skills += skill.functionEx * ChannelNum.functionEx; }

    if(pageNum >= 5){ pageNum = 5; }
    
    result = unitPrice * pageNum + skills; // 기본가 * 쪽수 + 추가기능 기본가 * 채널수 

    let calculate = Math.floor(result*1.1);
    let calculateKmong = Math.floor(result*1.1*1.08);
    SetCalculate(calculate);
    SetCalculateKmong(calculateKmong);
  };

  const PdfDown = (e) => {
    if(CategorySelect!=="강의" && TypeSelect===''){
      alert("종류를 선택하세요.")
    }
    else if(CategorySelect==="프로그래밍" && !Object.values(TaskArea).some(value => value === true)){
      alert("작업 범주를 선택하세요.");
    }
    else{
      window.print();
    }
  }

  const InvoiceOpen = async (e) => {
    $('.Commission .CommissionSend').stop().slideDown(500);
  };

  const copyToClipboard = () => {
    let textToCopy = document.getElementById('text-to-copy1').innerText;
    textToCopy += '\n\n\n'; // 엔터 2번 추가
    textToCopy += document.getElementById('text-to-copy2').innerText;
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        console.log('Text copied to clipboard');
      })
      .catch(err => {
        console.error('Failed to copy text: ', err);
      });
  }

  return (
    <div className="Commission flex con w-full flex-col items-center gap-4">
      <div className="flex h-5"></div>
      <div className="CommissionForm flex w-full flex-col flex-1">
        <div className="flex flex-col gap-4">
          <div className="CommissionCategory flex">
            <label>카테고리</label>
            <select value={CategorySelect} onChange={CategoryChange}>
              <option value="">-- 선택하세요 --</option>
              <option value="프로그래밍">프로그래밍</option>
              <option value="디자인">디자인</option>
              <option value="강의">강의</option>
            </select>
          </div>
          {CategorySelect==="디자인" ? 
          <div className="CommissionType flex flex-col gap-3">
            <label className="flex">종류</label>
            <div className="flex flex-wrap">
              <select className="flex" value={TypeSelect} onChange={TypeChange}>
                <option value="">-- 선택하세요 --</option>
                <option value="명함">명함</option>
                <option value="카드">카드</option>
                <option value="전단지">전단지</option>
                <option value="카탈로그">카탈로그</option>
                <option value="배너">배너</option>
                <option value="간판">간판</option>
                <option value="기타_디자인">기타</option>
              </select>  
              {TypeSelect === "기타_디자인" ? 
              <input className="flex" value={TypeEx} type="text" onChange={TypeExChange} />: null}
            </div>          
          </div> : null }
          {CategorySelect==="프로그래밍" ? 
          <div className="CommissionType flex flex-col gap-4">
            <div className="CommissionNode flex flex-col gap-2">
            <label className="flex">종류</label>
              <div className="flex flex-wrap">
                <select className="flex" value={TypeSelect} onChange={TypeChange}>
                  <option value="">-- 선택하세요 --</option>
                  <option value="웹앱">웹앱 & 웹사이트</option>
                  <option value="어플">앱(어플)</option>
                  <option value="디버깅">디버깅</option>
                  <option value="기타_프로그래밍">기타</option>
                </select>
                {TypeSelect === "기타_프로그래밍" ? 
                <input className="flex" value={TypeEx} type="text" onChange={TypeExChange} /> : null}
              </div>
            </div>
            <div className="CommissionNode flex flex-col gap-2">
              <label className="flex">작업 범주</label>
              <div className="flex flex-wrap gap-3">
                <label className="flex gap-2">
                <input type="checkbox" checked={TaskArea.taskDesign} onChange={() => TaskAreaChange('taskDesign')}/> 디자인 </label>
                <label className="flex gap-2">
                <input type="checkbox" checked={TaskArea.taskCoding} onChange={() => TaskAreaChange('taskCoding')}/> 코딩 & 디버깅</label>
              </div>
            </div>
            <div className="CommissionNode flex flex-col gap-2">
              {TaskArea.taskCoding  ?
              <label className="flex">사용언어 및 환경</label> : null}
              {TaskArea.taskCoding ?
              <input className="flex" value={CodingSet} type="text" onChange={CodingSetChange} /> : null}
            </div>
            <div className="CommissionNode flex flex-col gap-2">
              {TaskArea.taskCoding ?
              <label className="flex">추가 구현 기능</label> : null}
              {TaskArea.taskCoding ?
              <div className="flex flex-col gap-4">
                <label className="flex gap-x-3 flex-wrap gap-y-2 ">
                  <input type="checkbox" checked={MainFunction.functionMember} onChange={() => MainFunctionChange('functionMember')}/> 로그인 & 회원관리 
                  <input type="number" min={1} value={ChannelNum.functionMember} onChange={(e) => ChannelNumChange('functionMember', e.target.value)}/>channel
                </label>
                <label className="flex gap-x-3 flex-wrap gap-y-2">
                  <input type="checkbox" checked={MainFunction.functionCraw_Api} onChange={() => MainFunctionChange('functionCraw_Api')}/> 크롤링 & API 
                  <input type="number" min={1} value={ChannelNum.functionCraw_Api} onChange={(e) => ChannelNumChange('functionCraw_Api', e.target.value)}/>channel
                </label>
                {/* <label className="flex gap-x-3 flex-wrap gap-y-2">
                  <input type="checkbox" checked={MainFunction.functionMail_Phone} onChange={() => MainFunctionChange('functionMail_Phone')}/> 메일, 문자보내기 & 전화걸기 
                  <input type="number" min={1} value={ChannelNum.functionMail_Phone} onChange={(e) => ChannelNumChange('functionMail_Phone', e.target.value)}/>channel
                </label> */}
                {/* <label className="flex gap-x-3 flex-wrap gap-y-2">
                  <input type="checkbox" checked={MainFunction.functionPayment} onChange={() => MainFunctionChange('functionPayment')}/> 결제하기 
                  <input type="number" min={1} value={ChannelNum.functionPayment} onChange={(e) => ChannelNumChange('functionPayment', e.target.value)}/>channel
                </label> */}
                <label className="flex gap-x-3 flex-wrap gap-y-2">
                  <input type="checkbox" checked={MainFunction.functionEx} onChange={() => MainFunctionChange('functionEx')}/> 기타 
                  {MainFunction.functionEx ? 
                  <input className="flex" value={MainFunctionEx} type="text" onChange={MainFunctionExChange} /> : null}
                  <input type="number" min={1} value={ChannelNum.functionEx} onChange={(e) => ChannelNumChange('functionEx', e.target.value)}/>channel
                </label>
              </div> : null}
            </div>
          </div> : null }

          <div className="CommissionCommon flex flex-col gap-3">
            {CategorySelect==="강의"  ?
            <label className="flex">강의 일수</label> : null}
            {CategorySelect==="강의"  ?
            <input className="flex w-20" value={Days} type="number" min={1} onChange={DaysChange} /> : null}

            {CategorySelect==="강의"  ?
            <label className="flex">강의 시간(1일 기준)</label> : null}
            {CategorySelect==="강의"  ?
            <input className="flex w-20" value={Times} type="number" min={1} onChange={TimesChange} /> : null}

            {CategorySelect==="프로그래밍" || CategorySelect==="디자인" ?
            <label className="flex">페이지(쪽) 수 ※ 5 : 페이지 무제한</label> : null}
            {CategorySelect==="프로그래밍" || CategorySelect==="디자인" ?
            <input className="flex w-20" value={PageNum} type="number" min={1} max={5} onChange={PageNumChange} /> : null}
          </div>
        
        </div>
      </div>

      <div className="CommissionPrint flex w-full flex-col flex-1 h-full" id="contentToPrint">
        <div className="Print flex flex-col gap-2" id="text-to-copy1">
          <i className="fa-regular fa-clone" onClick={copyToClipboard}></i>
          <i className="fa-solid fa-file-pdf" onClick={PdfDown}></i>
          
          <div className="flex items-center"><p>카테고리</p> {CategorySelect}</div>
          {CategorySelect!=="강의" ? 
          <div className="flex items-center"><p>종류</p> {TypeSelect} {TypeSelect === "기타_디자인" || TypeSelect === "기타_프로그래밍" ? `_${TypeEx}` : null} </div> : null}
          
          {CategorySelect!=="강의" && CategorySelect!=="" ? 
            <div className="flex items-center"><p>페이지(쪽) 수</p> {PageNum}</div> : null}
          
          {CategorySelect==="프로그래밍" ? 
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-x-3 gap-y-1 flex-wrap"> 
              <p>작업 범주</p>
              {TaskArea.taskDesign ? <div className="Pin">디자인</div> : null}
              {TaskArea.taskCoding ? <div className="Pin">코딩 & 디버깅</div> : null}
            </div> 

            {TaskArea.taskCoding ? 
            <div className="flex items-center"><p>사용언어 및 환경</p> {CodingSet} </div> : null}

            {TaskArea.taskCoding ? 
            <div className="flex items-center gap-x-4 gap-y-1 flex-wrap">
              <p>추가 구현 기능</p> 
              {MainFunction.functionMember ? <div className="Pin">로그인 & 회원관리 X {ChannelNum.functionMember}</div> : null}
              {MainFunction.functionCraw_Api ? <div className="Pin">크롤링 & API X {ChannelNum.functionCraw_Api}</div> : null} 
              {MainFunction.functionMail_Phone ? <div className="Pin">메일, 문자보내기 & 전화걸기 X {ChannelNum.functionMail_Phone}</div> : null} 
              {MainFunction.functionPayment ? <div className="Pin">결제하기 X {ChannelNum.functionPayment}</div> : null}  
              {MainFunction.functionEx ? <div className="Pin">기타 {MainFunctionEx} X {ChannelNum.functionEx}</div> : null}  
            </div> : null}

          </div>: null}

          {CategorySelect==="강의" ? `강의 일수 ${Days} X 강의 시간 ${Times}` : null}
        </div>

        <div className="Pays flex gap-2 flex-col" id="text-to-copy2">
          <div className="flex flex-wrap"> <p>NonFlatForm Cost</p> {Calculate}￦ ~  ※ VAT 포함, 기타 유지비 제외</div>
          <div className="flex flex-wrap"> <p>Kmong Cost</p> {CalculateKmong}￦ ~  ※ VAT 포함, 기타 유지비 제외</div>
        </div>
        <div className=" flex gap-4 mt-6 pl-4">
          <button className="ButtonForm2 flex" onClick={InvoiceOpen }>직접 거래하기</button>
          <a href="https://kmong.com/gig/534464" target="_blank" className="ButtonForm2 flex cursor-pointer">크몽으로 거래하기</a>
        </div>

      </div>

      <div className="CommissionSend flex flex-col flex-1 h-full w-full">
      {props.UserLoading ?
        <div className="flex flex-col justify-center items-center">
          <div className="flex text-4xl font-bold mb-10 mt-4">Contact</div>
          {props.UserDb.map(myinfo=>(
            <div className="flex flex-col w-full items-center" key={myinfo}>
              
              <div className="flex text-xl">Phone</div>
              <div className="flex font-normal text-2xl pt-4">{myinfo.Phone}</div>
              <div className="flex h-7"></div>
              <div className="flex text-xl">Email</div>
              <div className="flex font-normal text-2xl pt-4">{myinfo.Email}</div>
              <div className="flex h-7"></div>
              
            </div> ))}
        </div>
      : null}
    
      </div>

      <div className="flex h-20"></div>
    </div>
  );
}

export default Commission;
