import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from 'react-router-dom';
import $ from 'jquery';

import './Menu.css';

function Menu() {

  const[MBmenubar, SetMBmenubar]=useState(false);
  const MBmenus = useRef(null);

  const sideOutput = (e) => {
    if(e.currentTarget.nextElementSibling.classList.contains('Output')){
      e.currentTarget.nextElementSibling.classList.remove('Output');
      SetMBmenubar(false);
    }
    else{
      e.currentTarget.nextElementSibling.classList.add('Output');
      SetMBmenubar(true);
    }
  }

  function linkClicked(){
    if(MBmenus.current.nextElementSibling.classList.contains('Output')){
      MBmenus.current.nextElementSibling.classList.remove('Output');
      SetMBmenubar(false);
    }

    $(window).scrollTop(0);
    $(".Menu").stop().slideDown(0);
  }

  $(window).on('mousewheel',function(e){
    var wheel = e.originalEvent.wheelDelta;
    if(wheel>0){
      $(".Menu").stop().slideDown('fast');
    } 
    else {
      if(window.scrollY > 0 ){ 
        $(".Menu").stop().slideUp('fast'); 
      }
    }     
  });

  return (
    <div className="Menu">
      <div className="Menu_PC flex con items-center">
        <div className="Menu_PC-left flex justify-start">
          <Link to="" onClick={linkClicked}><img src="./img/mainLogo.png" alt="메인로고" /></Link>
        </div>
        <div className="flex flex-1"></div>
        <ul className="Menu_PC-right flex">
          <li className="flex flex-1 justify-center">
            <Link to="/About" onClick={linkClicked}>About</Link>
          </li>
          <li className="flex flex-1 justify-center">
            <Link to="/Works/Applications" onClick={linkClicked}>Works</Link>
          </li>
          <li className="flex flex-1 justify-center">
            <Link to="/Commission" onClick={linkClicked}>Commission</Link>
          </li>
          {/* <li className="flex justify-center">
            <Link to="/Contents" onClick={linkClicked}>Contents</Link>
          </li> */}
          <li className="flex flex-1 justify-center">
            <Link to="/Contact" onClick={linkClicked}>Contact</Link>
          </li>
        </ul>
      </div>

      <div className="Menu_MB flex w-full items-center">
        <div className="Menu_MB-left flex">
          <Link to="" onClick={linkClicked}><img src="./img/mainLogo.png" alt="메인로고" /></Link>
        </div>
        <div className="flex flex-1"></div>
        <div className="Menu_MB-right flex justify-end items-center" onClick={sideOutput} ref={MBmenus}>
          {MBmenubar ? 
          <i className="fa-solid fa-xmark"></i>
          : 
          <i className="fa-solid fa-bars"></i>
          }
        </div>
        <div className="Menu_MB-lists flex">
          <ul className="Menu_MB-lists-menu flex flex-1 flex-col">
            <li className="flex">
              <Link className="flex flex-1" to="/About" onClick={linkClicked}>About</Link>
            </li>
            <div className="menuListline flex w-full h-0.5"></div>
            <li className="flex">
              <Link className="flex flex-1" to="/Works/Applications" onClick={linkClicked}>Works</Link>
            </li>
            <div className="menuListline flex w-full h-0.5"></div>
            <li className="flex">
              <Link className="flex flex-1" to="/Commission" onClick={linkClicked}>Commission</Link>
            </li>
            <div className="menuListline flex w-full h-0.5"></div>
            {/* <li className="flex">
              <Link className="flex flex-1" to="/Contents" onClick={linkClicked}>Contents</Link>
            </li> */}
            {/* <div className="menuListline flex w-full h-0.5"></div> */}
            <li className="flex">
              <Link className="flex flex-1" to="/Contact" onClick={linkClicked}>Contact</Link>
            </li>
            <div className="menuListline flex w-full h-0.5"></div>
          </ul>
          <div className="menuLine flex justify-end w-0.5"></div>
        </div>
      </div>
    </div>
  );
}

export default Menu;
