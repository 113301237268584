import { React, useEffect, useState } from "react";
import './Contact.css';

function Contact(props) {

  return (
    <div className="Contact">
      {props.UserLoading ?
        <div className="Contact_PC flex con justify-center items-center">
          {props.UserDb.map(myinfo=>(
            <div className="Contact_PC-left flex flex-col" key={myinfo}>
              <div className="flex">Contact</div>
              <div className="flex flex-1 flex-col items-start">
                <div className="flex h-7"></div>
                <div className="flex">Phone</div>
                <div className="flex font-normal text-2xl pt-1">{myinfo.Phone}</div>
                <div className="flex h-7"></div>
                <div className="flex">Email</div>
                <div className="flex font-normal text-2xl pt-1">{myinfo.Email}</div>
                <div className="flex h-7"></div>
              </div>
            </div> ))}
        </div>
      : null}

    {props.UserLoading ?
      <div className="Contact_Tab flex flex-col tab_con justify-center">
        {props.UserDb.map(myinfo=>(
          <div className="Contact_Tab-top flex flex-1 flex-col" key={myinfo}>
            <div className="flex">Contact</div>
            <div className="flex h-7"></div>
            <div className="flex">Phone</div>
            <div className="flex font-normal text-base pt-1">{myinfo.Phone}</div>
            <div className="flex h-7"></div>
            <div className="flex">Email</div>
            <div className="flex font-normal text-base pt-1">{myinfo.Email}</div>
          </div>
        ))}
      </div>
      : null }

      {props.UserLoading ?
      <div className="Contact_MB flex flex-1 flex-col">
        <div className="flex h-10"></div>
        {props.UserDb.map(myinfo=>(
          <div className="Contact_MB-top flex flex-1 flex-col" key={myinfo}>
            <div className="flex">Contact</div>
            <div className="flex h-7"></div>
            <div className="flex">Phone</div>
            <div className="flex font-normal text-base pt-1">{myinfo.Phone}</div>
            <div className="flex h-7"></div>
            <div className="flex">Email</div>
            <div className="flex font-normal text-base pt-1">{myinfo.Email}</div>
          </div>
        ))}
      </div>
      : null }
    </div>
  );
}

export default Contact;
