import { Routes, Route, useLocation } from "react-router-dom";
import { React, useEffect, useState } from "react";
import './App.css';

import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs, doc } from "firebase/firestore"; // 데이터 읽어오기

import Menu from './Module/Menu.js';
import Footer from './Module/Footer.js';
import Home from "./pages/Home.js"
import About from "./pages/About.js"
import Works from "./pages/Works.js"
import Contact from "./pages/Contact.js"
import Contents from "./pages/Contents.js"
import Commission from "./pages/Commission.js"

function App() {

  // FireBase, TODO: Replace the following with your app's Firebase project configuration
  // See: https://support.google.com/firebase/answer/7015592
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
  };
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);

  const [UserDb, SetUserDb]=useState(null);
  let UserData=[];
  const [UserLoading, SetUserLoading]=useState(false);

  useEffect(()=>{
    LoadingData();
  },[])

  async function LoadingData(){
    SetUserLoading(false);
    const UserListRef = collection(db, "MyInfo"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    const querySnapshot = await getDocs(UserListRef);
    UserData.length=0;
    await querySnapshot.forEach((doc) => {
      UserData.push(doc.data());
    });
    SetUserDb(UserData);
    SetUserLoading(true);
  }

  return (
    <div className="App">
      <div className="Top-Menu-Fix">
        <Menu/>
      </div>
      <div className="Middle">
        <Routes>
            <Route exact path="" element={<Home db={db}/>}/>
            <Route exact path="/Works/Applications" element={<Works db={db} PageType={'Applications'}/>}/>
            <Route exact path="/Works/Content" element={<Works db={db} PageType={'Content'}/>}/>
            <Route exact path="/About" element={<About />}/>
            {/* <Route exact path="/Contents" element={<Contents />}/> */}
            <Route exact path="/Commission" element={<Commission  UserDb={UserDb} UserLoading={UserLoading} />}/>
            <Route exact path="/Contact" element={<Contact UserDb={UserDb} UserLoading={UserLoading} />}/> 
        </Routes>
      </div>
      <Footer/>
    </div>
  );
}

export default App;
