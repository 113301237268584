import { React, useEffect, useState } from "react";
import './WorkSub.css';

import { collection, getDocs, doc, orderBy, query } from "firebase/firestore"; // 데이터 읽어오기
import $ from 'jquery';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";	// 추가

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

SwiperCore.use([Navigation, Pagination, Autoplay])

function Application(props_sub) {

  const [WorksDb, SetWorksDb]=useState(null);
  const [WorkLoading, SetWorkLoading]=useState(false);

  const [LightBoxIMG, SetLightBoxIMG]=useState(null);
  const [thumbsSwiper, setThumbsSwiper] = useState(0);

  useEffect(()=>{
    LoadingData(props_sub.PageType);
  },[]) 

  useEffect(()=>{
    console.log(props_sub.PageType);
    LoadingData(props_sub.PageType);
  },[props_sub.PageType]) 

  async function LoadingData(PageType){
    SetWorkLoading(false);
    const UserListRef = collection(props_sub.db, PageType); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    const querySnapshot = await getDocs(query(UserListRef, orderBy("No", "asc")));
    let WorkData=[];
    querySnapshot.forEach((doc) => {
      WorkData.push(doc.data());
    });
    SetWorksDb(WorkData);
    SetWorkLoading(true);
  }

  function PageOn(e) {
    $(e.target).find('.PageIMGDetail').addClass('on');
    SetLightBoxIMG(e.target.src);
  }

  function PageOut(e){
    let Layer = $(e.target).find('.PageIMGDetailLayer');
    if(Layer.has(e.target).length===0){
      $(e.target).removeClass('on');
    }
  }

  console.clear();

  return (
    <div className="Application flex w-full">
      <div className="WorkSub_PC flex w-full flex-col">
        {WorkLoading &&  props_sub.PageType==="Applications" ?  
        <div className="Applications_PCP grid grid-cols-3 gap-x-6 gap-y-12 con">
          {WorksDb.map(works=>(
          <div className="Page grid flex flex-col cursor-pointer" key={works.index} onClick={PageOn}>
            <div className="Page_IMG flex justify-center items-center">
              <img src={works.AllIMG} alt={works.Name} className="flex w-full"></img>
            </div>
            <div className="flex h-6"></div>
            <div className="Page_Context">
              <div className="flex w-full">{works.Name}</div>
              <div className="flex w-full">종류 : {works.Type}</div>
            </div>
            <div className="PageIMGDetail flex justify-center items-center cursor-default" onClick={PageOut}>
              <div className="PageIMGDetailLayer flex w-full con">
                <div className="PageIMG_IMG flex w-full h-full justify-center items-center">
                  <img src={works.AllIMG} alt={works.Name} className="flex w-full"></img>
                </div>
                <div className="PageIMG_Context flex flex-1 flex-col">
                  <div className="flex text-xl mt-8 mb-2 cursor-pointer">
                    <a href={works.Link} target="_blank">
                      {works.Name} <i className="fa-solid fa-arrow-up-right-from-square text-base"></i>
                    </a>
                  </div>
                  <div className="flex mb-1">종류 : {works.Type}</div>
                  <div className="flex mb-1">타겟층 : {works.Target}</div>
                  <div className="flex mb-1">컨셉 : {works.Concept}</div>
                  <div className="flex mb-1">주조색 : {works.MainColor}</div>
                  <div className="flex mb-1">개발도구 : {works.DevelopmentTool}</div>
                  <div className="flex mb-1">개발자 : {works.Team}</div>
                  <div className="flex mb-4">배포일 : {works.UploadDate}</div>
                  <div className="flex">{works.Context}</div>
                </div>
              </div>
            </div>
          </div>
          ))}
        </div>
        : null}
        {WorkLoading &&  props_sub.PageType==="Content" ?  
        <div className="Content_PCP grid grid-cols-3 gap-x-6 gap-y-12 con">
          {WorksDb.map(works=>(
          <div className="Page flex flex-col cursor-pointer" key={works.index} onClick={PageOn}>
            <div className="Page_IMG flex justify-center items-center">
            {works.CoverIMG==="Globe" ? <i className="fa-solid fa-globe flex text-8xl"></i> :
              <img src={works.CoverIMG} alt={works.Name} className="flex w-full"></img>}
            </div>
            <div className="flex h-6"></div>
            <div className="Page_Context">
              <div className="flex w-full">{works.Name}</div>
              <div className="flex w-full">종류 : {works.Type}</div>
              <div className="flex w-full">사이즈 : {works.Size}</div>
            </div>
            <div className="PageIMGDetail flex justify-center items-center cursor-default" onClick={PageOut}>
              <div className="PageIMGDetailLayer flex w-full con">
                <div className="PageIMG_IMG flex w-full h-full justify-center items-center">
                  <img src={works.CoverIMG} alt={works.Name} className="flex w-full"></img>
                </div>
                <div className="PageIMG_Context flex flex-1 flex-col">
                  <div className="flex text-xl mt-8 mb-2">
                    <a href={works.Link} target="_blank">{works.Name}</a>
                  </div>
                  <div className="flex mb-1">종류 : {works.Type}</div>
                  <div className="flex mb-1">사이즈 : {works.Size}</div>
                  {works.Page==="" ? null : <div className="flex mb-1">페이지 : {works.Page}p</div> }
                  {/* <div className="flex mb-1">컨셉 : {Contents.Concept}</div> */}
                  <div className="flex mb-1">주조색 : {works.MainColor}</div>
                  <div className="flex mb-1">제작 : {works.Team}</div>
                  <div className="flex mb-1">완성일 : {works.UploadDate}</div>
                  <div className="flex w-full gap-2">
                    {works.Download==="" ? null : <div className="flex mb-1 cursor-pointer"> 
                      <a href={works.Download} target="_blank" className="LinkButton">Download <i className="fa-solid fa-download text-base"></i></a></div> }
                    {works.AssSite==="" ? null : <div className="flex mb-1 cursor-pointer"> 
                      <a href={works.AssSite} title={works.AssSite} target="_blank" className="LinkButton">
                        콘텐츠 바로가기 <i className="fa-solid fa-arrow-up-right-from-square text-base"></i>
                      </a>
                    </div> } 
                  </div>
                  <div className="flex mt-4">{works.Context}</div>
                </div>
              </div>
            </div>
          </div>
          ))}
        </div>
        : null}
        <div className="flex h-10"></div>
        <div className="flex h-20"></div>
      </div>
      <div className="WorkSub_Tab flex w-full flex-col">
        {WorkLoading  &&  props_sub.PageType==="Applications" ?  
        <div className="Application_TabP grid grid-cols-2 gap-x-6 gap-y-12 tab_con">
          {WorksDb.map(applications=>(
          <div className="Page flex flex-col" key={applications.index} onClick={PageOn}>
          <div className="Page_IMG flex justify-center items-center">
            <img src={applications.AllIMG} alt={applications.Name} className="flex w-full"></img>
          </div>
          <div className="flex h-6"></div>
          <div className="Page_Context">
            <div className="flex w-full">{applications.Name}</div>
            <div className="flex w-full">종류 : {applications.Type}</div>
          </div>
          <div className="PageIMGDetail flex justify-center items-center" onClick={PageOut}>
            <div className="PageIMGDetailLayer flex w-full tab_con">
              <div className="PageIMG_IMG flex w-full h-full justify-center items-center">
                <img src={applications.AllIMG} alt={applications.Name} className="flex w-full"></img>
              </div>
              <div className="PageIMG_Context flex flex-1 flex-col">
                <div className="flex text-lg mt-4 mb-2">
                  <a href={applications.Link} target="_blank">
                    {applications.Name} <i className="fa-solid fa-arrow-up-right-from-square text-base"></i>
                  </a>
                </div>
                <div className="flex mb-1">종류 : {applications.Type}</div>
                <div className="flex mb-1">타겟층 : {applications.Target}</div>
                <div className="flex mb-1">컨셉 : {applications.Concept}</div>
                <div className="flex mb-1">주조색 : {applications.MainColor}</div>
                <div className="flex mb-1">개발도구 : {applications.DevelopmentTool}</div>
                <div className="flex mb-1">개발자 : {applications.Team}</div>
                <div className="flex mb-4">배포일 : {applications.UploadDate}</div>
                <div className="flex">{applications.Context}</div>
              </div>
            </div>
          </div>
        </div>
          ))}
        </div>
        : null}
        {WorkLoading  &&  props_sub.PageType==="Content" ?  
        <div className="Content_TabP grid grid-cols-2 gap-x-6 gap-y-12 tab_con">
          {WorksDb.map(works=>(
          <div className="Page flex flex-col" key={works.index} onClick={PageOn}>
          <div className="Page_IMG flex justify-center items-center">
            {works.CoverIMG==="Globe" ? <i className="fa-solid fa-globe flex text-9xl"></i> :
              <img src={works.CoverIMG} alt={works.Name} className="flex w-full"></img>}
          </div>
          <div className="flex h-6"></div>
          <div className="Page_Context">
            <div className="flex w-full">{works.Name}</div>
            <div className="flex w-full">종류 : {works.Type}</div>
            <div className="flex w-full">사이즈 : {works.Size}</div>
          </div>
          <div className="PageIMGDetail flex justify-center items-center" onClick={PageOut}>
          <div className="PageIMGDetailLayer flex w-full tab_con">
            <div className="PageIMG_IMG flex w-full h-full justify-center items-center">
              <img src={works.CoverIMG} alt={works.Name} className="flex w-full"></img>
            </div>
            <div className="PageIMG_Context flex flex-1 flex-col">
              <div className="flex text-xl mt-4 mb-2">
                <a href={works.Link} target="_blank">{works.Name}</a>
              </div>
              <div className="flex mb-1">종류 : {works.Type}</div>
              <div className="flex mb-1">사이즈 : {works.Size}</div>
              {works.Page==="" ? null : <div className="flex mb-1">페이지 : {works.Page}p</div> }
              {/* <div className="flex mb-1">컨셉 : {Contents.Concept}</div> */}
              <div className="flex mb-1">주조색 : {works.MainColor}</div>
              <div className="flex mb-1">제작 : {works.Team}</div>
              <div className="flex mb-1">완성일 : {works.UploadDate}</div>
              <div className="flex w-full flex-wrap gap-2">
                {works.Download==="" ? null : <div className="flex mb-1 cursor-pointer"> 
                  <a href={works.Download} target="_blank" className="LinkButton">Download <i className="fa-solid fa-download text-base"></i></a></div> }
                {works.AssSite==="" ? null : <div className="flex mb-1 cursor-pointer"> 
                  <a href={works.AssSite} title={works.AssSite} target="_blank" className="LinkButton">
                    콘텐츠 바로가기 <i className="fa-solid fa-arrow-up-right-from-square text-base"></i>
                  </a>
                </div> }
              </div>
              <div className="flex mt-4">{works.Context}</div>
            </div>
          </div>
          </div>
        </div>
          ))}
        </div>
        : null}
        <div className="flex h-10"></div>
        <div className="flex h-20"></div>
      </div>
      <div className="WorkSub_MB flex w-full flex-col">
        {WorkLoading  &&  props_sub.PageType==="Applications" ?  
        <div className="Application_MBP grid grid-cols-1 gap-x-6 gap-y-12">
          {WorksDb.map(applications=>(
          <div className="Page flex w-full flex-col" key={applications.index} onClick={PageOn}>
          <div className="Page_IMG flex w-full justify-center items-center">
            <img src={applications.AllIMG} alt={applications.Name} className="flex w-full"></img>
          </div>
          <div className="flex h-6"></div>
          <div className="Page_Context">
            <div className="flex w-full">{applications.Name}</div>
            <div className="flex w-full">종류 : {applications.Type}</div>
          </div>
          <div className="PageIMGDetail flex justify-center items-center" onClick={PageOut}>
            <div className="PageIMGDetailLayer flex w-full flex-col">
              <div className="PageIMG_IMG flex w-full h-full justify-center items-center">
                <img src={applications.AllIMG} alt={applications.Name} className="flex w-full"></img>
              </div>
              <div className="PageIMG_Context flex flex-1 flex-col">
                <div className="flex mt-2 mb-2 text-lg">
                  <a href={applications.Link} target="_blank">
                    {applications.Name} <i className="fa-solid fa-arrow-up-right-from-square text-sm"></i>
                  </a>
                </div>
                <div className="flex mb-1">종류 : {applications.Type}</div>
                <div className="flex mb-1">타겟층 : {applications.Target}</div>
                <div className="flex mb-1">컨셉 : {applications.Concept}</div>
                <div className="flex mb-1">주조색 : {applications.MainColor}</div>
                <div className="flex mb-1">개발도구 : {applications.DevelopmentTool}</div>
                <div className="flex mb-1">개발자 : {applications.Team}</div>
                <div className="flex mb-4">배포일 : {applications.UploadDate}</div>
                <div className="flex">{applications.Context}</div>
              </div>
            </div>
          </div>
        </div>
          ))}
        </div>
        : null}
        {WorkLoading  &&  props_sub.PageType==="Content"?  
        <div className="Content_MBP grid grid-cols-1 gap-x-6 gap-y-12">
          {WorksDb.map(work=>(
          <div className="Page flex w-full flex-col" key={work.index} onClick={PageOn}>
          <div className="Page_IMG flex w-full justify-center items-center">
            {work.CoverIMG==="Globe" ? <i className="fa-solid fa-globe flex text-9xl"></i> :
              <img src={work.CoverIMG} alt={work.Name} className="flex w-full"></img>}
          </div>
          <div className="flex h-6"></div>
          <div className="Page_Context">
            <div className="flex w-full">{work.Name}</div>
            <div className="flex w-full">종류 : {work.Type}</div>
            <div className="flex w-full">사이즈 : {work.Size}</div>
          </div>
          <div className="PageIMGDetail flex justify-center items-center" onClick={PageOut}>
            <div className="PageIMGDetailLayer flex w-full flex-col">
              <div className="PageIMG_IMG flex w-full h-full justify-center items-center">
                <img src={work.CoverIMG} alt={work.Name} className="flex w-full"></img>
              </div>
              <div className="PageIMG_Context flex flex-1 flex-col">
                <div className="flex text-lg mt-2 mb-2"> {work.Name} </div>
                <div className="flex mb-1">종류 : {work.Type}</div>
                <div className="flex mb-1">사이즈 : {work.Size}</div>
                {work.Page==="" ? null : <div className="flex mb-1">페이지 : {work.Page}p</div> }
                {/* <div className="flex mb-1">컨셉 : {Contents.Concept}</div> */}
                <div className="flex mb-1">주조색 : {work.MainColor}</div>
                <div className="flex mb-1">제작 : {work.Team}</div>
                <div className="flex mb-1">완성일 : {work.UploadDate}</div>
                <div className="flex flex-wrap w-full gap-2">
                  {work.Download==="" ? null : <div className="flex mb-1 cursor-pointer"> 
                    <a href={work.Download} target="_blank" className="LinkButton">Download <i className="fa-solid fa-download text-base"></i></a></div> }
                  {work.AssSite==="" ? null : <div className="flex mb-1 cursor-pointer"> 
                    <a href={work.AssSite} title={work.AssSite} target="_blank" className="LinkButton">
                      콘텐츠 바로가기 <i className="fa-solid fa-arrow-up-right-from-square text-base"></i>
                    </a>
                  </div> }
                </div>
                <div className="flex mt-4">{work.Context}</div>
              </div>
            </div>
          </div>
        </div>
          ))}
        </div>
        : null}
        <div className="flex h-10"></div>
        <div className="flex h-20"></div>
        
      </div>
    </div>
  );
}

export default Application;