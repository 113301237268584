import { React, useEffect, useRef, useState } from "react";
import { Link, useLocation } from 'react-router-dom';

import './About.css';

function About(props) {
  
  return (
    <div className="About w-full h-full">
      <div className="About_PC flex flex-col w-full h-full con items-center gap-6">
        <div className="flex flex-col">
          <p className="Title">About Som Company</p>
        </div>
        <div className="Intro_Bar flex w-full items-center justify-center flex-col">
          <div className="flex w-full flex-1">
            <i className="fa-brands fa-figma text-7xl"></i>
          </div>
          <p className="mt-3">어바웃 솜 컴퍼니는 디자인 부터 코딩까지 다 해드리는</p>
          <div className="flex h-2"></div>
          <p>프로그래밍 & 디자인 전문 외주업체입니다.</p>
          <div className="flex w-full justify-end items-end flex-1">
            <i className="fa-solid fa-code text-5xl"></i>
          </div>
        </div>
        <div className="Intro_Bar flex w-full items-center justify-center flex-col">
          <div className="flex gap-8 mb-8 mt-4 items-center">
            <i className="fa-brands fa-figma text-6xl"></i>
            <i className="fa-solid fa-cubes text-6xl"></i>
            <i className="fa-solid fa-code text-6xl"></i>
            <i class="fa-solid fa-spider text-4xl p-3 border-4 border-white rounded-lg"></i>
          </div>
          <p className="flex">디자인, 3D CAD 모델링, 웹앱개발, 크롤링 등</p>
          <p className="flex">바로 견적내고 제작합니다.</p>
          <Link to="/Comission" className="flex mt-5 text-lg">Go to Comission</Link>
        </div>
        <div className="Intro_Bar flex w-full items-center justify-center flex-col">
          <div className="flex gap-8 mb-6 mt-4 items-center">
            <i class="fa-solid fa-school-flag text-7xl"></i>
          </div>
          <p className="flex">코딩, 어렵지 않습니다.</p>
          <p className="flex">어바웃솜컴퍼니가 함께 공부합니다.</p>
        </div>
      </div>

      <div className="About_Tab flex flex-col w-full h-full items-center gap-6">
        <div className="flex flex-col">
          <p className="Title">About Som Company</p>
        </div>
        <div className="Intro_Bar flex w-full items-center justify-center flex-col">
          <div className="flex w-full flex-1">
            <i className="fa-brands fa-figma text-7xl"></i>
          </div>
          <p className="mt-3">어바웃 솜 컴퍼니는 디자인 부터 코딩까지 다 해드리는</p>
          <div className="flex h-2"></div>
          <p>프로그래밍 & 디자인 전문 외주업체입니다.</p>
          <div className="flex w-full justify-end items-end flex-1">
            <i className="fa-solid fa-code text-5xl"></i>
          </div>
        </div>
        <div className="Intro_Bar flex w-full items-center justify-center flex-col">
          <div className="flex gap-8 mb-8 mt-4 items-center">
            <i className="fa-brands fa-figma text-6xl"></i>
            <i className="fa-solid fa-cubes text-6xl"></i>
            <i className="fa-solid fa-code text-6xl"></i>
            <i class="fa-solid fa-spider text-4xl p-3 border-4 border-white rounded-lg"></i>
          </div>
          <p className="flex">디자인, 3D CAD 모델링, 웹앱개발, 크롤링 등</p>
          <p className="flex">바로 견적내고 제작합니다.</p>
          <Link to="/Comission" className="ButtonForm1 flex mt-5 text-lg">Go to Comission</Link>
        </div>
        <div className="Intro_Bar flex w-full items-center justify-center flex-col">
          <div className="flex gap-8 mb-6 mt-4 items-center">
            <i class="fa-solid fa-school-flag text-7xl"></i>
          </div>
          <p className="flex">코딩, 어렵지 않습니다.</p>
          <p className="flex">어바웃솜컴퍼니가 함께 공부합시다.</p>
          <Link to="/CodingSchool" className="ButtonForm1 flex mt-5 text-lg">Go to Coding School</Link>
        </div>
      </div>

      <div className="About_MB flex flex-col w-full h-full items-center gap-6">
        <div className="flex flex-col">
          <p className="Title">About<br></br>Som Company</p>
        </div>
        <div className="Intro_Bar flex w-full items-center justify-center flex-col">
          <div className="flex w-full flex-1">
            <i className="fa-brands fa-figma text-7xl"></i>
          </div>
          <p className="mt-3 text-2xl">어바웃 솜 컴퍼니는</p>
          <div className="flex h-4"></div>
          <p>디자인 부터 코딩까지 다 해드리는</p>
          <div className="flex h-2"></div>
          <p>프로그래밍 & 디자인 전문 외주업체입니다.</p>
          <div className="flex h-2"></div>

          <div className="flex w-full justify-end items-end flex-1">
            <i className="fa-solid fa-code text-5xl"></i>
          </div>
        </div>
        <div className="Intro_Bar flex w-full items-center justify-center flex-col">
          <div className="flex gap-8 mb-8 mt-4 items-center grid grid-cols-2">
            <i className="fa-brands fa-figma text-5xl items-center justify-center p-3 border-4 border-white rounded-lg"></i>
            <i className="fa-solid fa-cubes text-5xl items-center justify-center"></i>
            <i className="fa-solid fa-code text-4xl items-center justify-center"></i>
            <i class="fa-solid fa-spider items-center text-4xl justify-center p-3 border-4 border-white rounded-lg"></i>
          </div>
          <p className="flex">디자인, 3D CAD 모델링, 웹앱개발, 크롤링 등
          바로 견적내고 제작합니다.</p>
          <Link to="/Comission" className="ButtonForm1 flex mt-5 text-lg">Go to Comission</Link>
        </div>
        <div className="Intro_Bar flex w-full items-center justify-center flex-col">
          <div className="flex gap-8 mb-6 mt-4 items-center">
            <i class="fa-solid fa-school-flag text-7xl"></i>
          </div>
          <p className="flex">코딩, 어렵지 않습니다.</p>
          <p className="flex">어바웃솜컴퍼니가 함께 공부합시다.</p>
          <Link to="/CodingSchool" className="ButtonForm1 flex mt-5 text-lg">Go to Coding School</Link>
        </div>
      </div>
    </div>
  );
}

export default About;
