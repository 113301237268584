import { React, useEffect, useRef, useState } from "react";
import './Home.css';
import { Link } from "react-router-dom";

import { collection, getDocs, query, orderBy, getDoc, where, doc, deleteDoc } from "firebase/firestore"; // 데이터 읽어오기

import $ from 'jquery';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";	// 추가

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

SwiperCore.use([Navigation, Pagination, Autoplay])

function Home(props) {
  const [Social, SetSocial] = useState(null);
  const [SocialLoading, SetSocialLoading] = useState(false);

  useEffect(()=>{
    LoadingSocialData();
    $('.Home_PC a .context').stop().slideUp(0);
  },[])

  async function LoadingSocialData(){
    SetSocialLoading(false);
    const UserListRef = collection(props.db, "Social"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    const querySnapshot = await getDocs(query(UserListRef, orderBy("No", "asc"), where("OnOff","==", "On")));
    let ContactData=[];
    ContactData.length=0;
    querySnapshot.forEach((doc) => {
      ContactData.push(doc.data());
    });
    SetSocial(ContactData);
    SetSocialLoading(true);
  }

  function mouseover(e){
    $(e.currentTarget).find('.context').stop().slideDown('fast');
    $(e.currentTarget).siblings().find('.context').stop().slideUp('fast');
  }

  return (
    <div className="Home">
      <div className="Home_PC flex flex-col w-full h-full justify-center items-center">
        <div className="Home_PC-Upper flex con w-full flex-1">
          <div className="Home_PC-left flex flex-1 justify-end items-center">
            <div className="flex justify-center items-center">
              <img src="./img/mainProfile.png" alt="메인프로필" />
            </div>
            <div className="flex w-10"></div>
          </div>
          <div className="flex w-2"></div>
          <div className="Home_PC-right flex w-full flex-1 flex-col justify-center">
            <div className="flex mt-6">Hello!!</div>
            <div className="flex">디지털 풀옵션 서비스 전문, 어바웃 솜 컴퍼니입니다.</div>
            {SocialLoading ? 
            <div className="Home_PC_Sns-ball flex mt-4 gap-8">
              {Social.map(social=>(
              <div className="flex Home_PC_Sns-balls justify-center items-center" key={social.index}>
                <Link to={social.Contact} target="_blank" className="flex flex-1 justify-center items-center">
                  <img src={social.Icons} alt={social.Name}></img>
                </Link>
              </div> ))}
            </div> : null }
          </div>
        </div>
        <div className="Home_PC-Bottom flex w-full justify-center">
          <div className="flex w-full">
            <Link to="/About" className="flex flex-1 flex-col justify-center items-center" onMouseOver={mouseover}>
              <p className="text-xl">어바웃 솜 컴퍼니란?</p>
              <p className="context flex text-sm mt-4">어바웃 솜 컴퍼니에 대해 소개합니다.</p>
              <p className="context flex text-sm mt-4">Show More</p>
            </Link>
            <Link to="/Works/Applications" className="flex flex-1 flex-col justify-center items-center" onMouseOver={mouseover}>
              <p className="text-xl">어바웃 솜's Works </p>
              <p className="context flex text-sm mt-4">어바웃 솜 컴퍼니의 작업물들을 소개합니다.</p>
              <p className="context flex text-sm mt-4">Show More</p>
            </Link>
            <Link to="/Commission" className="flex flex-1 flex-col justify-center items-center" onMouseOver={mouseover}>
              <p className="text-xl">견적·의뢰</p>
              <p className="context flex text-sm mt-4">디자인부터 코딩까지, 무엇이든 의뢰하세요.</p>
              <p className="context flex text-sm mt-4">Show More</p>
            </Link>
            <Link to="/Study" className="flex flex-1 flex-col justify-center items-center" onMouseOver={mouseover}>
              <p className="text-xl">코딩 스쿨</p>
              <p className="context flex text-sm mt-4">어바웃 솜 컴퍼니와 함께 스킬 Up</p>
              <p className="context flex text-sm mt-4">Show More</p>
            </Link>
          </div>
        </div>
      </div>

      <div className="Home_Tab flex flex-col w-full h-full justify-center items-center">
        <div className="Home_Tab-Upper flex w-full flex-1 items-center">
          <div className="Home_Tab-left flex flex-1 justify-end items-center">
            <div className="flex justify-center items-center">
              <img src="./img/mainProfile.png" alt="메인프로필" />
            </div>
          </div>
          <div className="flex w-8"></div>
          <div className="Home_Tab-right flex w-full flex-1 flex-col justify-center">
            <div className="flex mt-4">Hello!!</div>
            <div className="flex pl-2">디지털 풀옵션 서비스 전문,<br></br>어바웃 솜 컴퍼니입니다.</div>
          </div>
        </div>
        {SocialLoading ? 
        <div className="Home_Tab_Sns-ball flex mt-4 gap-8 flex-wrap">
          {Social.map(social=>(
          <div className="flex Home_Tab_Sns-balls justify-center items-center" key={social.index}>
            <Link to={social.Contact} target="_blank" className="flex flex-1 justify-center items-center">
              <img src={social.Icons} alt={social.Name}></img>
            </Link>
          </div> ))}
        </div> : null }
        <div className="flex h-16"></div>
        <div className="Home_Tab-Bottom flex w-full justify-center">
          <div className="flex w-full flex-col">
            <Link to="/About" className="flex flex-1 flex-col justify-center items-center">
              <p className="text-xl">어바웃 솜 컴퍼니란?</p>
              <p className="context flex text-sm mt-4">어바웃 솜 컴퍼니에 대해 소개합니다.</p>
              <p className="context flex text-sm mt-4">Show More</p>
            </Link>
            <Link to="/Works/Applications" className="flex flex-1 flex-col justify-center items-center">
              <p className="text-xl">어바웃 솜's Works </p>
              <p className="context flex text-sm mt-4">어바웃 솜 컴퍼니의 작업물들을 소개합니다.</p>
              <p className="context flex text-sm mt-4">Show More</p>
            </Link>
            <Link to="/Commission" className="flex flex-1 flex-col justify-center items-center">
              <p className="text-xl">견적·의뢰</p>
              <p className="context flex text-sm mt-4">디자인부터 코딩까지, 무엇이든 의뢰하세요.</p>
              <p className="context flex text-sm mt-4">Show More</p>
            </Link>
            <Link to="/Study" className="flex flex-1 flex-col justify-center items-center">
              <p className="text-xl">코딩 스쿨</p>
              <p className="context flex text-sm mt-4">어바웃 솜 컴퍼니와 함께 스킬 Up</p>
              <p className="context flex text-sm mt-4">Show More</p>
            </Link>
          </div>
        </div>
      </div>

      <div className="Home_MB flex flex-col w-full h-full justify-center items-center">
        <div className="Home_MB-Upper tab_con flex w-full flex-1 flex-col items-center">
          <div className="Home_MB-left flex flex-1 justify-end items-center">
            <div className="flex justify-center items-center">
              <img src="./img/mainProfile.png" alt="메인프로필" />
            </div>
          </div>
          <div className="flex h-8"></div>
          <div className="Home_MB-right flex w-full flex-1 flex-col justify-center">
            <div className="flex">Hello!!</div>
            <div className="flex pl-2">디지털 풀옵션 서비스 전문,<br></br>어바웃 솜 컴퍼니입니다.</div>
            {SocialLoading ? 
            <div className="Home_MB_Sns-ball flex mt-6 gap-8 flex-wrap">
              {Social.map(social=>(
              <div className="flex Home_PC_Sns-balls justify-center items-center" key={social.index}>
                <Link to={social.Contact} target="_blank" className="flex flex-1 justify-center items-center">
                  <img src={social.Icons} alt={social.Name}></img>
                </Link>
              </div> ))}
            </div> : null }
          </div>
        </div>
        <div className="Home_MB-Bottom flex w-full justify-center">
          <div className="flex w-full flex-col">
            <Link to="/About" className="flex flex-1 flex-col justify-center items-center">
              <p className="text-xl">어바웃 솜 컴퍼니란?</p>
              <p className="context flex text-sm mt-4">어바웃 솜 컴퍼니에 대해 소개합니다.</p>
              <p className="context flex text-sm mt-4">Show More</p>
            </Link>
            <Link to="/Works/Applications" className="flex flex-1 flex-col justify-center items-center">
              <p className="text-xl">어바웃 솜's Works </p>
              <p className="context flex text-sm mt-4">어바웃 솜 컴퍼니의 작업물들을 소개합니다.</p>
              <p className="context flex text-sm mt-4">Show More</p>
            </Link>
            <Link to="/Commission" className="flex flex-1 flex-col justify-center items-center">
              <p className="text-xl">견적·의뢰</p>
              <p className="context flex text-sm mt-4">디자인부터 코딩까지, 무엇이든 의뢰하세요.</p>
              <p className="context flex text-sm mt-4">Show More</p>
            </Link>
            <Link to="/Study" className="flex flex-1 flex-col justify-center items-center">
              <p className="text-xl">코딩 스쿨</p>
              <p className="context flex text-sm mt-4">어바웃 솜 컴퍼니와 함께 스킬 Up</p>
              <p className="context flex text-sm mt-4">Show More</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
